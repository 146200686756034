// HERO SECTION

document.addEventListener("DOMContentLoaded", function(){

  gsap.from(".hero-desc", {
    x: 100, 
    opacity: 0,
    delay: .5,
    duration: .75,
    filter:"blur(8px)",
    ease: "slow(0.5, 0.8, true)"
  })
  
  const heroDesc = gsap.timeline({
    scrollTrigger: {
      trigger: "#hero",
      toggleActions: "restart pause reverse reverse",
      start: "top top",
      end: "+=250px",
      scrub: 3,
    },
  })
  
  heroDesc.fromTo(".hero-desc", { y: 0, x: 0 }, { y: -200, x: 0 })

})

gsap.to('.zebra', { 
  scrollTrigger: {
    trigger: "#hero",
    toggleActions: "restart pause reverse reverse",
    start: "top top",
    end: "+=250px",
    scrub: 2,
  },
  y: "-100px", 
})

// VALUES SECTION 


gsap.to("#values .overlay", { 
  scrollTrigger: {
    trigger: "#values",
    toggleActions: "restart pause reverse reverse",
    start: "-=100px",
    scrub: true,
  },
  scale: 75,
  x: '100%'
})

gsap.to('#values .values-container', { 
  scrollTrigger: {
    trigger: "#values",
    toggleActions: "restart pause reverse reverse",
    start: "-=1000px",
    scrub: true,
  },
  y: "0", 
})

const fadeInColTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".values-container",
    toggleActions: "restart pause reverse reverse",
    start: "-=850px",
  },
})

fadeInColTl.from('#values .value-container', {
  opacity: 0,
  y: 100,
  stagger: .5,
})

gsap.to('#values .pattern', { 
  scrollTrigger: {
    trigger: "#values",
    toggleActions: "restart pause reverse reverse",
    start: "-=250px",
    scrub: 15,
  },
  y: -200, 
})

// BUSINESS AREAS SECTIOn 

gsap.from('.fade-in-text', { 
  scrollTrigger: {
    trigger: ".fade-in-trigger",
    toggleActions: "restart pause reverse reverse",
    start: "-=550px",
  },
  opacity: 0,
  y: 100,
})

// BALLS

const shwoBallsTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".fade-in-trigger",
    toggleActions: "restart pause reverse reverse",
    start: "-=300px",
    end: "+=300px",
    scrub: 3,
  },
})

shwoBallsTl.fromTo(".ball-2", { x: "-1000%" }, { x: 0 })
shwoBallsTl.fromTo(".ball-3", { x: "-1000%" }, { x: 0 })
shwoBallsTl.fromTo(".ball-4", { x: "-1000%" }, { x: 0 })

gsap.to('.balls-wrapper', { 
  scrollTrigger: {
    trigger: ".balls-wrapper",
    toggleActions: "restart pause reverse reverse",
    start: "-=50px",
    end: "+=300px",
    scrub: 1,
  },
  y: 500,
})

// const rotateBallsTl = gsap.timeline({
//   scrollTrigger: {
//     trigger: ".balls-wrapper",
//     toggleActions: "restart pause reverse reverse",
//     start: "+=350px",
//     end: "+=450px",
//     scrub: 1,
//     markers: true
//   },
// })

// rotateBallsTl.fromTo(".ball-2", { y: 0 }, { y: 200 })
// rotateBallsTl.fromTo(".ball-3", { y: 0 }, { y: 475 })
// rotateBallsTl.fromTo(".ball-4", { y: 0 }, { y: 845 })

gsap.to('.ball-2', { 
  scrollTrigger: {
    trigger: ".balls-wrapper",
    toggleActions: "restart pause reverse reverse",
    start: "+=100px",
    end: "+=300px",
    scrub: 1
  },
  y: 200,
})

gsap.to('.ball-3', { 
  scrollTrigger: {
    trigger: ".balls-wrapper",
    toggleActions: "restart pause reverse reverse",
    start: "+=100px",
    end: "+=300px",
    scrub: 1
  },
  y: 475,
})

gsap.to('.ball-4', { 
  scrollTrigger: {
    trigger: ".balls-wrapper",
    toggleActions: "restart pause reverse reverse",
    start: "+=100px",
    end: "+=300px",
    scrub: 1
  },
  y: 845,
})

const ballsContentTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".ball-content",
    toggleActions: "restart pause reverse reverse",
    start: "+=175px",
    end: "+=300px",
    scrub: 3,
  },
})

ballsContentTl.fromTo('.ball-content', { y: 100, opacity: 0 }, { y: 0, opacity: 1, stagger: 1.5})